<h1 mat-dialog-title>Crear Contacto</h1>
<form #information="ngForm" (ngSubmit)="information.form.valid ? onSubmit() : ''">
  <div mat-dialog-content>
    <div class="container-lg">
      <div style="text-align: right" *ngIf="isLoading">
        <mat-icon matSuffix>
          <mat-spinner diameter="30"></mat-spinner>
        </mat-icon>
      </div>
      <div class="step-form">
        <div class="col-12">
          <div class="form-group">
            <label>{{form[step].inputs.sourcePhone.label}}</label>
            <input type="text" name="sourcePhone" class="form-control" [(ngModel)]="form[step].inputs.sourcePhone.value"
                   #sourcePhone="ngModel" (keypress)="numberOnly($event)"
                   [ngClass]="{ 'is-invalid': information.submitted && sourcePhone.invalid }"
                   placeholder="Ex: 09999999 - 0222222"
                   required>
            <div *ngIf="information.submitted && sourcePhone.invalid" class="invalid-feedback">
              <div *ngIf="sourcePhone.errors.required">Teléfono es requerido</div>
            </div>
          </div>
          <div class="form-group">
            <label>{{form[step].inputs.phoneType.label}}</label>
            <select class="form-control"
                    [ngClass]="{ 'is-invalid': information.submitted && phoneType.invalid }"
                    name="phoneType" #phoneType="ngModel" [(ngModel)]="form[step].inputs.phoneType.value" required>
              <option value="">Seleccione Tipo</option>
              <option *ngFor="let tipos of Tipos" [ngValue]="tipos">{{tipos}}</option>
            </select>
            <div *ngIf="information.submitted && phoneType.invalid" class="invalid-feedback">
              <div *ngIf="phoneType.errors.required">Seleccione Tipo</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="button-modal-close-container">
    <button mat-button class="btn btn-danger btn-next" [mat-dialog-close]="">Cancelar</button>
    <button mat-button class="btn btn-primary btn-next">Guardar
    </button>
  </div>
</form>

