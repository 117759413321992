import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';

@Component({
  selector: 'app-form-radio',
  template: `
    <div
      class="form-group"
      [formGroup]="group">
      <label>{{ config.label }} <strong class="text-danger" *ngIf="config.required">*</strong></label>
      <select [formControlName]="config.name"
              data-name="{{config.name}}"
              class="form-control"
              [ngClass]="isSubmit && field.errors?'is-invalid':''">
        <option value="">{{ config.placeholder }}</option>
        <option *ngFor="let option of config.options" [value]="option.value">
          {{ option.label }}
        </option>
      </select>
      <app-form-validate *ngIf="isSubmit && field.errors" [field]="field"></app-form-validate>
    </div>
  `
})

export class FormRadioComponent implements Field, OnInit {
  config: FieldConfig;
  group: FormGroup;
  isSubmit: boolean;
  field;

  ngOnInit(): void {
    this.field = this.group.controls[this.config.name];
  }
}
