import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {AuthGuard} from '../guards/auth.guard';

export interface menuItem {
  code: string,
  icon: string,
  label: string,
  action: string,
}

@Injectable({providedIn: 'root'})
export class MenuDrawerService {
  private showMenuSubject: BehaviorSubject<boolean>;
  public showMenu: Observable<boolean>;
  public menuList: menuItem[] = [
    {
      code: 'administration:true',
      icon: 'person_add_alt_1',
      label: 'Usuarios',
      action: '/administration/user',
    },
    {
      code: 'administration:true',
      icon: 'clear_all',
      label: 'Rol',
      action: '/administration/role',
    },
    {
      code: 'alerts:true',
      icon: 'notification_important',
      label: 'Alertas',
      action: '/administration/tray',
    },
    {
      code: 'alerts:true',
      icon: 'reports',
      label: 'Reportes',
      action: '/report/report-alert',
    },
  ];

  constructor(private authGuard: AuthGuard) {
    this.showMenuSubject = new BehaviorSubject<boolean>(false);
    this.showMenu = this.showMenuSubject.asObservable();
  }

  public setShowMenuValue(status: boolean): void {
    this.showMenuSubject.next(status);
  }

  public getMenuItems() {
    return this.menuList.filter(item => this.authGuard.verifyPermission(item.code));
  }
}
