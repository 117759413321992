import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { TransportService } from './transport.service';

const { apiUrl } = environment;

export interface User {
  id: string;
  code?: string;
  document?: string;
  fullName?: string;
  username?: string;
  phone?: string;
  email?: string;
  token?: string;
  permissions?: string[];
}

export interface UserLogin {
  username: string;
  password?: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(private http: HttpClient, private transportService: TransportService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValues(): User {
    return this.currentUserSubject.value;
  }

  public get currentUserValue(): User {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  static getExpiration(): moment.Moment {
    const expiration = localStorage.getItem('expiresAt');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  isLoggedIn = () => moment().isBefore(AuthService.getExpiration());

  signIn({ username, password }: UserLogin) {
    return this.http.post<any>(`${apiUrl}/auth/login`, {
        transport: this.transportService.encryptData({
          username,
          password
        })
      }
    ).pipe(map(
      user => {
        if (user && user.token) {
          const expiresAt = moment().add(user.expiresIn[0], user.expiresIn[1]);
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('expiresAt', JSON.stringify(expiresAt.valueOf()));
          this.currentUserSubject.next(user);
        }
        return user;
      }
    ));
  }

  getAgentByRole(roleId) {
    return this.http.get<User[]>(`${apiUrl}/auth/user/agents/${roleId}`).toPromise();
  }

  signOut() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('expiresAt');
    this.currentUserSubject.next(null);
  }
}
