import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FieldConfig } from '../../../models/field-config.interface';

@Component({
  selector: 'app-form-row',
  template: `
    <div class="row" *ngFor="let row of rows">
      <ng-container *ngFor="let field of row">
        <div *ngIf="!field.hide" class="col">
          <ng-container appDynamicField [config]="field"
                        [group]="form" [isSubmit]="isSubmit"
          ></ng-container>
        </div>
      </ng-container>
    </div>`
})
export class FormRowComponent implements OnInit {
  @Input() rows;
  @Input() form;
  @Input() isSubmit;

  constructor() {

  }

  ngOnInit(): void {
  }
}
