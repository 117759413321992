<h1 mat-dialog-title>Crear Contacto</h1>
<form #information="ngForm" (ngSubmit)="information.form.valid ? onSubmit() : ''">
  <div mat-dialog-content>
    <div class="container-lg">
      <div style="text-align: right" *ngIf="isLoading">
        <mat-icon matSuffix>
          <mat-spinner diameter="30"></mat-spinner>
        </mat-icon>
      </div>
      <div class="step-form">
        <div class="col-12">
          <div class="form-group">
            <label>{{form[step].inputs.clientType.label}}</label>
            <select class="form-control"
                    [ngClass]="{ 'is-invalid': information.submitted && clientType.invalid }"
                    name="clientType" #clientType="ngModel" [(ngModel)]="form[step].inputs.clientType.value" required>
              <option value="">Seleccione Tipo</option>
              <option *ngFor="let tipos of TiposContacto" [ngValue]="tipos">{{tipos}}</option>
            </select>
            <div *ngIf="information.submitted && clientType.invalid" class="invalid-feedback">
              <div *ngIf="clientType.errors.required">Seleccione Tipo</div>
            </div>
          </div>
          <div class="form-group">
            <label>{{form[step].inputs.fullName.label}}</label>
            <input type="text" name="fullName" class="form-control" [(ngModel)]="form[step].inputs.fullName.value"
                   #fullName="ngModel" (keypress)="numberOnly($event)"
                   [ngClass]="{ 'is-invalid': information.submitted && fullName.invalid }"
                   placeholder="Ex: Alex Yepez"
                   required>
            <div *ngIf="information.submitted && fullName.invalid" class="invalid-feedback">
              <div *ngIf="fullName.errors.required">Nombre es requerido</div>
            </div>
          </div>
          <div class="form-group">
            <label>{{form[step].inputs.document.label}}</label>
            <input type="text" name="document" class="form-control" [(ngModel)]="form[step].inputs.document.value"
                   #document="ngModel" (keypress)="numberOnly($event)"
                   [ngClass]="{ 'is-invalid': information.submitted && document.invalid }"
                   placeholder="Ex: 100256364"
                   required>
            <div *ngIf="information.submitted && document.invalid" class="invalid-feedback">
              <div *ngIf="document.errors.required">Nombre es requerido</div>
            </div>
          </div>
          <div class="form-group">
            <label>{{form[step].inputs.gender.label}}</label>
            <select class="form-control"
                    [ngClass]="{ 'is-invalid': information.submitted && gender.invalid }"
                    name="gender" #gender="ngModel" [(ngModel)]="form[step].inputs.gender.value" required>
              <option value="">Seleccione Género</option>
              <option *ngFor="let tipos of Tipos" [ngValue]="tipos">{{tipos}}</option>
            </select>
            <div *ngIf="information.submitted && gender.invalid" class="invalid-feedback">
              <div *ngIf="gender.errors.required">Seleccione Género</div>
            </div>
          </div>
          <div class="form-group">
            <label>{{form[step].inputs.civilStatus.label}}</label>
            <select class="form-control"
                    [ngClass]="{ 'is-invalid': information.submitted && civilStatus.invalid }"
                    name="civilStatus" #civilStatus="ngModel" [(ngModel)]="form[step].inputs.civilStatus.value"
                    required>
              <option value="">Seleccione Estado</option>
              <option *ngFor="let tipos of CivilStatus" [ngValue]="tipos">{{tipos}}</option>
            </select>
            <div *ngIf="information.submitted && civilStatus.invalid" class="invalid-feedback">
              <div *ngIf="civilStatus.errors.required">Seleccione Estado Civil</div>
            </div>
          </div>
          <div class="form-group">
            <label>{{form[step].inputs.economicSector.label}}</label>
            <select class="form-control"
                    [ngClass]="{ 'is-invalid': information.submitted && economicSector.invalid }"
                    name="economicSector" #economicSector="ngModel" [(ngModel)]="form[step].inputs.economicSector.value"
                    required>
              <option value="">Seleccione Sector</option>
              <option *ngFor="let tipos of CivilStatus" [ngValue]="tipos">{{tipos}}</option>
            </select>
            <div *ngIf="information.submitted && economicSector.invalid" class="invalid-feedback">
              <div *ngIf="economicSector.errors.required">Seleccione Sector</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="button-modal-close-container">
    <button mat-button class="btn btn-danger btn-next" [mat-dialog-close]="">Cancelar</button>
    <button mat-button class="btn btn-primary btn-next">Guardar
    </button>
  </div>
</form>

