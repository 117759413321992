// my-modal.component.ts
import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Collection, CollectionService} from '../../../services/collection-view.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-client-phone',
  templateUrl: './client-phone.component.html',
  styleUrls: ['./client-phone.component.css'],
  providers: [CollectionService],
})
export class ClientPhoneComponent {
  step = 1;
  isLoading = false;
  form = {
    1: {
      inputs: {
        date: {
          label: 'Fecha',
          name: 'created_at',
          type: 'date',
          value: moment().format('YYYY-MM-DD')
        },
        client: {
          label: 'Cliente',
          name: 'client_id',
          type: 'string',
          value: ''
        },
        sourcePhone: {
          label: 'Teléfono',
          name: 'source_phone',
          type: 'string',
          value: ''
        },
        status: {
          label: 'Estado',
          name: 'status',
          type: 'string',
          value: 'ACTIVE'
        },
        phoneType: {
          label: 'Tipo de Teléfono',
          name: 'phone_type',
          type: 'string',
          value: ''
        }
      }
    },
  };
  // Phone Types
  Tipos: any = ['MOVIL', 'FIJO'];

  constructor(
    public dialogRef: MatDialogRef<ClientPhoneComponent>, private collectionService: CollectionService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form[this.step].inputs.client.value = this.data.clientId;
  }

  numberOnly(event) {
    console.log();
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onSubmit() {
    this.isLoading = true;
    const model = {createdAt: '', clientId: '', phone: '', sourcePhone: '', status: '', phoneType: ''};
    model.createdAt = this.form[this.step].inputs.date.value;
    model.clientId = this.form[this.step].inputs.client.value;
    model.phone = this.form[this.step].inputs.sourcePhone.value;
    model.sourcePhone = this.form[this.step].inputs.sourcePhone.value;
    model.status = this.form[this.step].inputs.status.value;
    model.phoneType = this.form[this.step].inputs.phoneType.value;
    this.collectionService.savePhone(model).then((data: any) => {
      if (data) {
        Swal.fire({
          title: 'Perfecto',
          html: 'Contacto fue creado',
          icon: 'success'
        }).then(() => {
          this.isLoading = false;
        });
      } else {
        Swal.fire({
          title: 'Lo sentimos',
          html: 'No se pudo crear el contacto',
          icon: 'error'
        }).then(() => {
          this.isLoading = false;
        });
      }
    });
  }

}
