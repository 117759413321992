import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Client } from '../../services/client.service';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-client-details',
  template: `
    <h2 mat-dialog-title>{{client.fullName}} ({{client.clientType}})</h2>
    <div mat-dialog-content>
      <div class="row">
        <app-client-view [client]="client"></app-client-view>
      </div>
      <div class="row">
        <div class="container-lg">
          <div>
            <table mat-table [dataSource]="client.phones">
              <!-- Number Column -->
              <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef>Número</th>
                <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
              </ng-container>

              <!-- Type Phone Column -->
              <ng-container matColumnDef="phoneType">
                <th mat-header-cell *matHeaderCellDef>Tipo</th>
                <td mat-cell *matCellDef="let element"> {{element.phoneType}} </td>
              </ng-container>

              <!-- Status Column -->
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Estado</th>
                <td mat-cell *matCellDef="let element"> {{element.status === 'ACTIVE' ? 'ACTIVO' : 'INACTIVO'}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSize]="5"></mat-paginator>
          </div>
        </div>
      </div>
    </div>
    <div mat-dialog-actions class="button-modal-close-container">
      <button mat-button class="button-modal-close" [mat-dialog-close]="">Cerrar</button>
    </div>
  `
})

export class ClientDetailsComponent implements AfterViewInit {
  displayedColumns: string[] = ['phone', 'phoneType', 'status'];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public dialogRef: MatDialogRef<ClientDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public client: Client
  ) {
  }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Items por página';
  }
}
