import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';

@Component({
  selector: 'app-form-input',
  template: `
    <div
      class="form-group"
      [formGroup]="group">
      <label>{{config.label}} <strong class="text-danger" *ngIf="config.required">*</strong></label>
      <input type="{{config.inputType || 'text'}}"
             data-name="{{config.name}}"
             class="form-control"
             autocomplete="off"
             maxlength="{{config.maxlength}}"
             [hidden]="config.hide"
             [ngClass]="isSubmit && field.errors?'is-invalid':''"
             [attr.placeholder]="config.placeholder"
             [formControlName]="config.name">
      <app-form-validate *ngIf="isSubmit && field.errors" [field]="field"></app-form-validate>
    </div>`
})
export class FormInputComponent implements Field, OnInit {
  config: FieldConfig;
  group: FormGroup;
  isSubmit: boolean;
  field;


  ngOnInit(): void {
    this.field = this.group.controls[this.config.name];
  }
}
