import { Component, OnInit, Input } from '@angular/core';
import { Collection } from '../../services/collection-admin.service';

@Component({
  selector: 'app-details-collection',
  template: `
    <mat-tab-group mat-align-tabs="start">
      <mat-tab label="Generales">
        <div class="d-flex flex-row mt-2">
          <div class="d-flex flex-column mr-5">
            <label><strong>Cliente: </strong> {{collection.clients?.fullName || 'No disponible'}}</label>
            <label><strong>Código operación: </strong> {{collection.syncId}}</label>
            <label><strong>Fecha de ajud.: </strong> {{collection.awardDate|date}}</label>
            <label><strong>Agencia: </strong> {{collection.agency}}({{collection.agencyCod}})</label>
            <label><strong>Email: </strong> {{collection.email || 'No disponible'}}</label>
          </div>
          <div class="d-flex flex-column">
            <label><strong>Frecuencia: </strong> {{collection.frequency}}</label>
            <label><strong>Débito: </strong> {{collection.debit}}</label>
            <label><strong>Estado sinc.: </strong> {{collection.syncStatus}}</label>
            <label><strong>Última act.: </strong> {{collection.updatedAt|date}}</label>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Cobranza">
        <div class="d-flex flex-row mt-2">
          <div class="d-flex flex-column mr-5">
            <label><strong>Estado: </strong> {{collection.collectionState}}</label>
            <label><strong>Estado Gestión: </strong> {{collection.managementStatus}}</label>
            <label><strong>Cuotas: </strong> {{collection.totalFees}}</label>
            <label><strong>Cuotas pendientes: </strong> {{collection.pendingFees}}</label>
            <label><strong>Cuotas pagadas: </strong> {{collection.paidFees}}</label>
          </div>
          <div class="d-flex flex-column">
            <label><strong>Días de mora: </strong> {{collection.daysPastDue}}</label>
            <label><strong>Fecha de pago.: </strong> {{collection.paymentDate|date}}</label>
            <label><strong>Fecha vencimiento: </strong> {{collection.dueDate|date}}</label>
            <label><strong>Cuota Mensual: </strong>{{collection.monthlyFeeAmount|currency}}</label>
            <label><strong>Total Pendiente: </strong> {{collection.totalAmount|currency}}</label>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>`
})

export class DetailsCollectionComponent implements OnInit {
  @Input() collection: Collection

  constructor() {
  }

  ngOnInit() {
  }
}
