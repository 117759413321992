import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-button',
  styleUrls: ['form-button.component.css'],
  template: `
    <div
      class="form-group"
      [formGroup]="group">
      <button type="submit" [ngClass]="config.class">
        <ng-template [ngIf]="!disabled">
          {{ config.label }}
        </ng-template>
        <ng-template [ngIf]="disabled">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          ... cargando
        </ng-template>
      </button>
    </div>
  `,
})
export class FormButtonComponent implements OnInit {
  @Input() config;
  @Input() disabled = false;
  @Input() group: FormGroup;

  ngOnInit(): void {
  }
}
