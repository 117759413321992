import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as CryptoJS from 'crypto-js';

const { transportSecret } = environment;

@Injectable({ providedIn: 'root' })
export class TransportService {

  constructor() {
  }

  encryptData(data: any) {
    const payment = typeof data === 'string' ? data : JSON.stringify(data);
    const encryptData = CryptoJS.AES.encrypt(payment, transportSecret);
    return encryptData.toString();
  }
}
