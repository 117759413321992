import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { ClientPhone } from './client.service';
import { CollectionPayment } from './collection-admin.service';

const {apiUrl} = environment;

export interface Collection {
  id: number;
  name: string;
  status: string;
  startAt: string;
  endAt: string;
}

export interface CollectionProvider {
  count: number,
  rows: Collection[]
}

@Injectable()
export class CollectionService {
  constructor(private http: HttpClient) {
  }


  getDataGridClientsByCollections(page, limit, collectionId) {
    return this.http.get<CollectionProvider>(`${apiUrl}/client/findAll`, {
      params: {
        page, limit, collectionId
      }
    });
  }

  getDataGridManagementsByCollections(page, limit, collectionId) {
    return this.http.get<CollectionProvider>(`${apiUrl}/collection/management/findAll`, {
      params: {
        _page: page, _limit: limit, collection_id: collectionId
      }
    });
  }

  getDataGridPaymentsByCollections(page, limit, collectionId) {
    return this.http.get<{ count: number, rows: CollectionPayment[] }>(`${apiUrl}/collection/findAllPayments`, {
      params: {
        _page: page, _limit: limit, collection_id: collectionId
      }
    });
  }

  getDataGridPhonesByClient(client) {
    return this.http.get<ClientPhone[]>(`${apiUrl}/client/findAllPhonesByClient`, {
      params: {
        client_id: client
      }
    });
  }

  savePhone(model) {
    return this.http.post<{ id }>(`${apiUrl}/client/phone/create`, model).toPromise();
  }
}
