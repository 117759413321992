import { Component, OnInit, Input } from '@angular/core';

@Component({
        selector: 'app-card-container',
        template: `
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header {{headerClass}}" *ngIf="headerTitle">
                            <h5 class="mb-0 text-white">{{headerTitle}}</h5>
                        </div>
                        <div class="card-body">
                            <ng-content></ng-content>
                        </div>
                    </div>
                </div>
            </div>
        `
    }
)

export class CardContainerComponent implements OnInit {
    @Input() headerTitle;
    @Input() headerClass = 'bg-info';

    ngOnInit(): void {
    }
}
