import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CollectionManagement } from '../../services/collection.service';
import { Client, ClientPhone, ClientService } from '../../services/client.service';
import { find } from 'lodash';

@Component({
  selector: 'app-management-client-phones',
  providers: [ClientService],
  template: `
    <div class="d-flex flex-column">
      <div ngbDropdown class="d-inline-block">
        <button class="btn btn-outline-info client-action" id="dropdownBasic1" ngbDropdownToggle>
          {{contactSelected ? contactSelected.fullName : '- Sin Contacto - '}}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button class="client-action" ngbDropdownItem *ngFor="let contact of contacts"
                  (click)="selectContact(contact)">
            {{contact.fullName}} ({{contact.clientType}})
          </button>
        </div>
      </div>
      <app-phone-list *ngIf="contactSelected" [data]="contactSelected.phones"></app-phone-list>
      <div class="d-flex mt-1">
        <input type="tel" placeholder="Nuevo número"
               class="form-control form-control-sm col-sm-6"
               [(ngModel)]="phoneModel.sourcePhone" (change)="onChangeNumber()">
        <select [(ngModel)]="phoneModel.phoneType" (change)="onChangeNumber()"
                class="form-control form-control-sm col-sm-4">
          <option value="MOVIL">MÓVIL</option>
          <option value="FIJO">FIJO</option>
        </select>
        <button class="btn btn-sm btn-xs btn-success" (click)="savePhone()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div *ngIf="invalidPhone" class="invalid-feedback" style="display: inline">número invalido</div>
    </div>
  `,
  styles: [`
    .client-action {
      max-width: 100%;
      padding: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .btn-xs {
      height: 31px;
      border-radius: 0;
    }
  `]
})

export class ManagementClientPhonesComponent implements OnChanges {
  @Input() collection: CollectionManagement;
  @Output() handleSelectedContact: EventEmitter<Client> = new EventEmitter<Client>();
  contacts: Client[];
  contactSelected: Client;
  contactId;
  phoneModel: ClientPhone = {
    clientId: null,
    phoneType: 'MOVIL',
    phone: null,
    sourcePhone: null
  };
  invalidPhone = false;

  constructor(
    private clientService: ClientService
  ) {
  }

  async ngOnChanges(changes: SimpleChanges): Promise<any> {
    if (this.collection) {
      this.contacts = await this.clientService.getAllByCollection(this.collection.collectionId);
      if (this.contacts?.length > 0) {
        this.contactSelected = this.contacts.find(row => row.clientType === 'TITULAR');
        this.handleSelectedContact.emit(this.contactSelected);
      }
    }
  }

  selectContact(contact: Client) {
    this.contactSelected = contact;
    this.handleSelectedContact.emit(this.contactSelected);
  }

  savePhone() {
    if (this.phoneModel.sourcePhone) {
      this.phoneModel.phone = this.phoneModel.sourcePhone;
      this.phoneModel.clientId = this.contactSelected.id;
      this.clientService.savePhone(this.phoneModel).then(
        result => {
          if (!find(this.contactSelected.phones, row => row.id === result.id)) {
            this.contactSelected.phones.push(result);
          }
          this.phoneModel.sourcePhone = null;
        }
      );
    }
  }

  onChangeNumber() {
    const {phoneType, sourcePhone} = this.phoneModel;
    if (sourcePhone) {
      if (phoneType == 'MOVIL') {
        this.invalidPhone = !sourcePhone.toString().match(/^\d{10}$/);
      } else if (phoneType == 'FIJO') {
        this.invalidPhone = !sourcePhone.toString().match(/^\d{9}$/);
      }
    } else {

    }
  }
}
