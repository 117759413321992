import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicFormComponent } from './containers/dynamic-form/dynamic-form.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { FormButtonComponent } from './components/form-button/form-button.component';
import { DynamicFieldDirective } from './components/dynamic-field.directive';
import { FormValidateComponent } from './components/form-validate/form-validate.component';
import { FormTextareaComponent } from './components/form-textarea/form-textarea.component';
import { FormRowComponent } from './containers/dynamic-form/components/form-row.component';
import { FormRadioComponent } from './components/form-radio/form-radio.component';


@NgModule({
  declarations: [DynamicFieldDirective, DynamicFormComponent, FormInputComponent, FormSelectComponent,
    FormButtonComponent, FormTextareaComponent, FormValidateComponent, FormRowComponent, FormRadioComponent,
    DynamicFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [DynamicFormComponent, DynamicFormComponent],
  entryComponents: [
    FormButtonComponent, FormInputComponent, FormSelectComponent, FormTextareaComponent
  ]
})
export class DynamicFormModule {
}
