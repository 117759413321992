<h1 mat-dialog-title>Lista de Contactos</h1>
<div mat-dialog-content>
  <div class="container-lg">
    <div>
      <table mat-table [dataSource]="dataSource">
        <!-- Number Column -->
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef>Número</th>
          <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
        </ng-container>

        <!-- Type Phone Column -->
        <ng-container matColumnDef="phoneType">
          <th mat-header-cell *matHeaderCellDef>Tipo</th>
          <td mat-cell *matCellDef="let element"> {{element.phone_type}} </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Estado</th>
          <td mat-cell *matCellDef="let element"> {{element.status === 'ACTIVE' ? 'ACTIVO' : 'INACTIVE'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSize]="5"></mat-paginator>
    </div>
  </div>
</div>
<div mat-dialog-actions class="button-modal-close-container">
  <button mat-button class="button-modal-close" [mat-dialog-close]="">Cerrar</button>
</div>
