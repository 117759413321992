import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ToolbarComponent } from '../components/toolbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { layoutMainComponent } from '../components/layout-main.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MyModalComponent } from './componentes/modals/my-modal.component';
import { ClientPhoneComponent } from './componentes/formClientPhone/client-phone.component';
import { ClientComponent } from './componentes/formClient/client.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { DetailsCollectionComponent } from '../components/collection/details-collection.component';
import { ClientDetailsComponent } from '../components/collection/client-details.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClientViewComponent } from '../components/collection/client-view.component';
import { ManagementCollectionDetailComponent } from '../components/management/management-collection-detail.component';
import { ManagementClientPhonesComponent } from '../components/management/management-client-phones.component';
import { PhoneListComponent } from '../components/call/phone-list.component';
import { DynamicFormModule } from '../modules/dynamic-form/dynamic-form.module';
import { CardContainerComponent } from './card-container.component';

@NgModule({
  declarations: [
    ToolbarComponent, layoutMainComponent,
    MyModalComponent, ClientPhoneComponent,
    ClientComponent, DetailsCollectionComponent,
    ClientDetailsComponent, ClientViewComponent,
    ManagementCollectionDetailComponent,
    ManagementClientPhonesComponent,
    CardContainerComponent,
    PhoneListComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    SweetAlert2Module.forRoot(),
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    NgbModule,
    MatDialogModule,
    MatTabsModule,
    NgSelectModule,
    DynamicFormModule,
    NgbAlertModule
  ],
  exports: [
    MatCardModule, MatFormFieldModule,
    MatInputModule, MatIconModule,
    MatProgressSpinnerModule,
    MatToolbarModule, ToolbarComponent,
    MatButtonModule, MatMenuModule,
    MatSidenavModule, layoutMainComponent,
    MatTableModule, MatPaginatorModule,
    MatSelectModule, MatTabsModule,
    DetailsCollectionComponent,
    SweetAlert2Module,
    NgSelectModule,
    ClientDetailsComponent,
    ClientViewComponent,
    ManagementCollectionDetailComponent,
    ManagementClientPhonesComponent,
    PhoneListComponent,
    DynamicFormModule,
    NgbAlertModule,
    CardContainerComponent
  ],
  providers: []
})
export class SharedModule {
}

