import { Component, Input, OnInit } from '@angular/core';
import { CollectionManagement } from '../../services/collection.service';

@Component({
  selector: 'app-management-collection-detail',
  template: `
    <div class="d-flex">
      <div class="d-flex flex-column">
        <label><strong>Cliente: </strong> {{collection.clientFullName || 'No disponible'}}</label>
        <label><strong>Código operación: </strong> {{collection.syncId}}</label>
        <label><strong>Agencia: </strong> {{collection.agency}}({{collection.agencyCod}})</label>
        <label><strong>Última act.: </strong> {{collection.updatedAt|date}}</label>
        <label><strong>Frecuencia: </strong> {{collection.frequency}}</label>
        <label><strong>Débito: </strong> {{collection.debit}}</label>
        <label><strong>Estado: </strong> {{collection.collectionState}}</label>
        <label><strong>Estado Gestión: </strong> {{collection.managementStatus}}</label>
        <label><strong>Cuotas: </strong> {{collection.totalFees}}</label>
      </div>
      <div class="d-flex flex-column">
        <label><strong>Cuotas pendientes: </strong> {{collection.pendingFees}}</label>
        <label><strong>Cuotas pagadas: </strong> {{collection.paidFees}}</label>
        <label><strong>Días mora: </strong> {{collection.daysPastDue}}</label>
        <label><strong>Fecha pago.: </strong> {{collection.paymentDate|date}}</label>
        <label><strong>Fecha vencimiento: </strong> {{collection.dueDate|date}}</label>
        <label><strong>Cuota Mensual: </strong>{{collection.monthlyFeeAmount|currency}}</label>
        <label><strong>Total Pendiente: </strong> {{collection.totalAmount|currency}}</label>
      </div>
    </div>
  `
})

export class ManagementCollectionDetailComponent implements OnInit {
  @Input() collection: CollectionManagement;

  constructor() {
  }

  ngOnInit() {
  }
}
