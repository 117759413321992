// my-modal.component.ts
import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Collection, CollectionService} from '../../../services/collection-view.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-client-phone',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css'],
  providers: [CollectionService],
})
export class ClientComponent {
  step = 1;
  isLoading = false;
  form = {
    1: {
      inputs: {
        collectionId: {
          label: 'Colección',
          name: 'collectionId',
          type: 'string',
          value: ''
        },
        clientType: {
          label: 'Tipo de Contacto',
          name: 'clientType',
          type: 'string',
          value: ''
        },
        fullName: {
          label: 'Nombre del Contacto',
          name: 'fullName',
          type: 'string',
          value: ''
        },
        document: {
          label: 'Documento',
          name: 'document',
          type: 'string',
          value: ''
        },
        gender: {
          label: 'Género',
          name: 'gender',
          type: 'string',
          value: ''
        },
        civilStatus: {
          label: 'Estado Civil',
          name: 'civilStatus',
          type: 'string',
          value: ''
        },
        economicSector: {
          label: 'Sector Económico',
          name: 'economicSector',
          type: 'string',
          value: ''
        },
        email: {
          label: 'Correo',
          name: 'email',
          type: 'email',
          value: ''
        },
        address: {
          label: 'Dirección',
          name: 'address',
          type: 'string',
          value: ''
        },
        province: {
          label: 'Provincia',
          name: 'province',
          type: 'string',
          value: ''
        },
        canton: {
          label: 'Cantón',
          name: 'canton',
          type: 'string',
          value: ''
        },
        parish: {
          label: 'Parroquia',
          name: 'parish',
          type: 'string',
          value: ''
        },
        neighborhood: {
          label: 'Barrio',
          name: 'neighborhood',
          type: 'string',
          value: ''
        },
        latitude: {
          label: 'Latitud',
          name: 'latitude',
          type: 'string',
          value: ''
        },
        longitude: {
          label: 'Longitud',
          name: 'longitude',
          type: 'string',
          value: ''
        },
        syncClientId: {
          label: 'Sincronización Cliente',
          name: 'syncClientId',
          type: 'string',
          value: ''
        },
      }
    },
  };
  // Gender Types
  Tipos: any = ['MASCULINO', 'FEMENINO'];
  // Contact Types
  TiposContacto: any = ['TITULAR', 'GARANTE'];
  // Civil Status Types
  CivilStatus: any = ['CASADO', 'DIVORCIADO', 'SEPARACION DE BIENES', 'SOLTERO', 'UNION LIBRE', 'VIUDO'];
  // Economic Sector Types
  EconomicSector: any = ['AGRICOLA', 'COMERCIO', 'PECUARIA', 'PESCA', 'SERVICIOS', 'TRANSFORMACION'];

  constructor(
    public dialogRef: MatDialogRef<ClientComponent>, private collectionService: CollectionService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  numberOnly(event) {
    console.log();
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onSubmit() {
    this.isLoading = true;
    const model = {createdAt: '', clientId: '', phone: '', sourcePhone: '', status: '', phoneType: ''};
    model.createdAt = this.form[this.step].inputs.date.value;
    model.clientId = this.form[this.step].inputs.client.value;
    model.phone = this.form[this.step].inputs.sourcePhone.value;
    model.sourcePhone = this.form[this.step].inputs.sourcePhone.value;
    model.status = this.form[this.step].inputs.status.value;
    model.phoneType = this.form[this.step].inputs.phoneType.value;
    this.collectionService.savePhone(model).then((data: any) => {
      if (data) {
        Swal.fire({
          title: 'Perfecto',
          html: 'Contacto fue creado',
          icon: 'success'
        }).then(() => {
          this.isLoading = false;
        });
      } else {
        Swal.fire({
          title: 'Lo sentimos',
          html: 'No se pudo crear el contacto',
          icon: 'error'
        }).then(() => {
          this.isLoading = false;
        });
      }
    });
  }

}
