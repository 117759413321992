import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';

@Component({
  selector: 'app-form-textarea',
  template: `
    <div
      class="form-group"
      [formGroup]="group">
      <label>{{config.label}} <strong class="text-danger" *ngIf="config.required">*</strong></label>
      <textarea
        class="form-control"
        data-name="{{config.name}}"
        [ngClass]="isSubmit && field.errors?'is-invalid':''"
        [formControlName]="config.name"></textarea>
      <app-form-validate *ngIf="isSubmit && field.errors" [field]="field"></app-form-validate>
    </div>`
})
export class FormTextareaComponent implements Field, OnInit {
  config: FieldConfig;
  group: FormGroup;
  isSubmit: boolean;
  field;

  constructor() {
  }

  ngOnInit() {
    this.field = this.group.controls[this.config.name];
  }

}
