import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ClientPhone, ClientService } from '../../services/client.service';


@Component({
  selector: 'app-phone-list',
  providers: [ClientService],
  template: `
    <div class="d-flex flex-column mt-2">
      <div class="d-flex mt-1" *ngFor="let phone of data">
        <span *ngIf="!phone.onEdit"
              [ngStyle]="phone.status==='INACTIVE'?{'text-decoration': 'line-through'}:{}">{{phone.phone}}
        </span>
        <input *ngIf="phone.onEdit" class="form-control form-control-sm col-sm-11" [(ngModel)]="phone.phone">
        <mat-icon class="phone-icon" *ngIf="!phone.onEdit" (click)="edit(phone.id)">
          create_outlined
        </mat-icon>
        <mat-icon class="edit-icon text-success" *ngIf="phone.onEdit" (click)="onUpdate(phone.id)">
          check_circle_outline
        </mat-icon>
        <mat-icon class="phone-icon text-danger" *ngIf="!phone.onEdit && phone.status==='ACTIVE'"
                  (click)="onDelete(phone.id)">
          delete_outline
        </mat-icon>
        <button *ngIf="!phone.onEdit&& phone.status==='ACTIVE'"
                class="btn btn-call btn-success"
                (click)="callParent(phone.phone)">
          <mat-icon class="phone-icon">phone_enabled_outlined</mat-icon>
        </button>
      </div>
    </div>`,
  styles: [`
    .btn-call {
      border-radius: 12px;
      height: 24px;
      width: 24px;
      padding: 3px 0 0 0;
    }

    .phone-icon {
      font-size: 18px;
      height: 18px;
      width: 18px;
    }

    .edit-icon {
      margin: 5px;
    }
  `]
})
export class PhoneListComponent implements OnInit {
  @Input() data: ClientPhone[];
  @Output() callBack = new EventEmitter();
  onEdit = false;

  constructor(
    private clientService: ClientService
  ) {
  }

  callParent(phone) {
    this.callBack.next(phone);
  }

  edit(key) {
    this.data.find((phone, i) => {
      if (phone.id === key) {
        this.data[i].onEdit = true;
        return true;
      }
    });
  }

  onUpdate(key) {
    const phoneModel = this.data.find((phone, i) => {
      if (phone.id === key) {
        this.data[i].onEdit = false;
        this.data[i].status = 'ACTIVE';
        return true;
      }
    });
    this.clientService.updatePhone(phoneModel, phoneModel.id)
  }

  onDelete(key) {
    const result = confirm('¿Está seguro de bloquear el teléfono?');
    if (result) {
      const phoneModel = this.data.find((phone, i) => {
        if (phone.id === key) {
          this.data[i].status = 'INACTIVE';
          return true;
        }
      });
      this.clientService.updatePhone(phoneModel, phoneModel.id)
    }
  }

  ngOnInit() {
  }
}
