import { Component, OnInit } from '@angular/core';
import { MenuDrawerService, menuItem } from '../services/menu-drawer.service';

@Component({
  selector: 'app-layout-main',
  template: `
    <mat-drawer-container class="main-container">
      <mat-drawer #drawer [mode]="'push'" [(opened)]="showMenu" (closed)="closedMenu()">
        <button *ngFor="let item of menuList" mat-menu-item routerLink="{{item.action}}">
          <mat-icon>{{item.icon}}</mat-icon>
          <span>{{item.label}}</span>
        </button>
      </mat-drawer>
      <mat-drawer-content>
        <app-toolbar></app-toolbar>
        <ng-content></ng-content>
      </mat-drawer-content>
    </mat-drawer-container>
  `
})

export class layoutMainComponent implements OnInit {
  showMenu: boolean = false;
  menuList: menuItem[];

  constructor(private menuDrawerService: MenuDrawerService) {
  }

  ngOnInit() {
    this.menuDrawerService.showMenu.subscribe(status => {
      if (status) {
        this.menuList = this.menuDrawerService.getMenuItems();
      }
      this.showMenu = status;
    });
  }

  closedMenu() {
    this.menuDrawerService.setShowMenuValue(false);
  }
}
