import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: 'auth', loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule) },
  {
    path: 'administration',
    loadChildren: () => import('./views/administration/administration.module').then(m => m.AdministrationModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'report',
    loadChildren: () => import('./views/report/report.module').then(m => m.ReportModule),
    canActivate: [ AuthGuard ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
