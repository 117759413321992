// my-modal.component.ts
import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Collection, CollectionService} from '../../../services/collection-view.service';
import {catchError, map, startWith, switchMap, timeout} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-my-modal',
  templateUrl: './my-modal.component.html',
  styleUrls: ['./my-modal.component.css'],
  providers: [CollectionService],
})
export class MyModalComponent implements AfterViewInit {
  displayedColumns: string[] = ['phone', 'phoneType', 'status'];
  dataSource = null;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild('paginatorClient', {read: MatPaginator}) paginatorClient: MatPaginator;
  // tableDisplayColumn: string[] = ['phone', 'phoneType', 'date', 'status'];
  // tableResultLength = 0;
  // tableData: Collection[];
  // isLoadingResults = true;

  constructor(
    public dialogRef: MatDialogRef<MyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dataSource = new MatTableDataSource(this.data.data);
    // this.loadDataClientsByCollection();
  }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Items por página';
    this.dataSource.paginator = this.paginator;
  }

  // onNoClick(): void {
  //   this.dialogRef.close({
  //     food: ''
  //   });
  // }


  onActions(action, row) {

  }

  loadDataClientsByCollection() {
    // console.log(this.data);
    // console.log(this.paginatorClient);
    // this.paginatorClient.page.pipe(startWith({}), switchMap(() => {
    //   this.isLoadingResults = true;
    //   return this.collectionService.getDataGridClientsByCollections(
    //     this.paginatorClient.pageIndex, this.paginatorClient.pageSize, 1
    //   );
    // }), map(data => {
    //   this.isLoadingResults = false;
    //   console.log(data);
    //   this.tableResultLength = data.count;
    //   return data.rows;
    // }), catchError(() => {
    //   this.isLoadingResults = false;
    //   return null;
    // })).subscribe((data: Collection[]) => this.tableData = data);
  }

}
