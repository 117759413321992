import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const {apiUrl} = environment;

export interface Client {
  id: number;
  collectionId: number;
  clientType: string;
  fullName: string;
  document: string;
  gender: string;
  civilStatus: string;
  economicSector: string;
  email: string;
  address: string;
  province: string;
  canton: string;
  parish: string;
  city: string;
  phones?: ClientPhone[];
  neighborhood: string;
  latitude: string;
  longitude: string;
  syncClientId: string;
}

export interface ClientPhone {
  id?: number;
  clientId?: number;
  phoneType: string;
  sourcePhone: string;
  phone: string;
  status?: string;
  createdAt?: string;
  createdUserId?: number;
  updatedAt?: string;
  updatedUserId?: number;
  deletedAt?: string;
  deletedUserId?: number;
  onEdit?: boolean;
}


@Injectable()
export class ClientService {
  constructor(private http: HttpClient) {
  }

  getPhoneByClient({client_id}) {
    return this.http.get <[{
      id: number,
      phone: string,
      status: string,
      count_contacted: number,
      count_notcontacted: number,
    }]>(`${apiUrl}/client/findAllPhonesByClient/${client_id}`).toPromise();
  }

  getAllByCollection(collectionId) {
    return this.http.get<Client[]>(`${apiUrl}/client/${collectionId}/all`).toPromise();
  }

  savePhone(model) {
    return this.http.post<any>(`${apiUrl}/client/phone/create`, model).toPromise();
  }

  updatePhone(model, id) {
    return this.http.post(`${apiUrl}/client/phone/update/${id}`, model).toPromise();
  }

  saveCall(model) {
    return this.http.post<any>(`${apiUrl}/client/call/create`, model).toPromise();
  }
}
