import { Component, OnInit, Input } from '@angular/core';
import { Client } from '../../services/client.service';

@Component({
  selector: 'app-client-view',
  template: `
    <div class="col d-flex flex-column">
      <label><strong>Cód. único: </strong> {{client.syncClientId || 'No disponible'}}</label>
      <label><strong>Documento: </strong> {{client.document || 'No disponible'}}</label>
      <label><strong>Sexo: </strong> {{client.gender || 'No disponible'}}</label>
      <label><strong>Estado civil: </strong> {{client.civilStatus || 'No disponible'}}</label>
      <label><strong>Actividad Económica: </strong> {{client.economicSector || 'No disponible'}}</label>
    </div>
    <div class="col d-flex flex-column">
      <label><strong>Email: </strong> {{client.email || 'No disponible'}}</label>
      <label><strong>Dirección: </strong>
        {{client.province ? client.province + ', ' : ''}}
        {{client.parish ? client.parish + ', ' : ''}}
        {{client.canton ? client.canton + ', ' : ''}}
        {{client.neighborhood ? client.neighborhood + ', ' : ''}}
        {{client.address ? client.address + ', ' : ''}}
      </label>
    </div>
  `
})

export class ClientViewComponent implements OnInit {
  @Input() client: Client

  constructor() {
  }

  ngOnInit() {
  }
}
