import { Component, OnInit } from '@angular/core';
import { AuthService, User } from '../services/auth.service';
import { Router } from '@angular/router';
import { MenuDrawerService } from '../services/menu-drawer.service';

@Component({
  selector: 'app-toolbar',
  template: `
    <mat-toolbar color="primary" class="custom-toolbar">
      <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="showMenu()">
        <mat-icon>menu</mat-icon>
      </button>
      <span>Riesgo Operativo</span>
      <span class="example-spacer"></span>
      <span>{{user.fullName}}</span>
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>account_circle</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onSignOut()">
          <mat-icon>power_settings_new</mat-icon>
          <span>Salir</span>
        </button>
      </mat-menu>
    </mat-toolbar>
  `
})

export class ToolbarComponent implements OnInit {
  user: User;

  constructor(private authService: AuthService, private router: Router, private menuDrawerService: MenuDrawerService) {
  }

  ngOnInit() {
    this.user = this.authService.currentUserValue;
  }

  onSignOut() {
    this.authService.signOut();
    this.router.navigateByUrl('/auth/login');
  }

  showMenu() {
    this.menuDrawerService.setShowMenuValue(true);
  }
}
